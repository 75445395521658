import { t } from "@/i18n-js/instance";
import { TRIGGER_RESOURCE_TYPE } from "../../../constants";
import type { EventOption } from "../types";
import { AccessGroupResource } from "./shared/AccessGroupResource";
import { AccessGroupSelector } from "./shared/AccessGroupSelector";

export const communityMemberJoined: Readonly<EventOption> = {
  label: t(
    "settings.workflows.form.events.community_member_joined_access_group.title",
  ),
  value: "community_member_joined_access_group",
  group: t("settings.workflows.form.event_groups.access_group"),
  description: t(
    "settings.workflows.form.events.community_member_joined_access_group.description",
  ),
  resource: TRIGGER_RESOURCE_TYPE.ACCESS_GROUP,
  formComponent: AccessGroupSelector,
  viewComponent: AccessGroupResource,
  icon: "space",
};
