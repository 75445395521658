import InfiniteScroll from "react-infinite-scroll-component";
import { useMutation } from "react-query";
import { t } from "@/i18n-js/instance";
import { chatRoomParticipantApi } from "@/react/api";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { useChatParticipantsApi } from "@circle-react/hooks/chatsV2";
import { UserImageStatus } from "@circle-react-shared/UserImageStatus";
import { BadgeV3 } from "@circle-react-shared/uikit/BadgeV3";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useToast } from "@circle-react-uikit/ToastV2";
import { mapParticipantsPerStatus, sortParticipants } from "./helpers";

export interface MemberListProps {
  chatRoom: any;
  refetchChatRoom: () => void;
}

export const MemberList = ({ chatRoom, refetchChatRoom }: MemberListProps) => {
  const toastr = useToast();
  const memberProfileDrawer = useProfileDrawer();

  const { uuid, current_participant: currentParticipant } = chatRoom;

  const {
    chatParticipants = [],
    refetchChatParticipants,
    totalCount: totalParticipantsCount,
    hasNextPage,
    fetchNextPage,
  } = useChatParticipantsApi(uuid);

  const participants = [...sortParticipants(chatParticipants)];
  const { mutateAsync: handleMakeAdmin } = useMutation<any, ApiError, any>(
    ({ participantId, status }) =>
      chatRoomParticipantApi.update(
        {
          uuid,
          id: participantId,
          admin: status,
        },
        { forReactQuery: true },
      ),
    {
      onSuccess: ({
        chat_room_participant: {
          name,
          roles: { admin },
        },
      }) => {
        const displayName = name || t("messaging.member"); //Fallback incase name not is set.
        refetchChatParticipants();
        refetchChatRoom();
        toastr.success(
          admin
            ? t("messaging.member_made_admin", { name: displayName })
            : t("messaging.member_removed_as_admin", {
                name: displayName,
              }),
          {
            duration: "short",
            shouldUseProgress: false,
          },
        );
      },
      onError: () => {
        toastr.error(t("request_failure_message"));
      },
    },
  );

  const { mutateAsync: removeParticipant } = useMutation<any, ApiError, any>(
    ({ participantId }) =>
      chatRoomParticipantApi.destroy(
        {
          uuid,
          id: participantId,
        },
        { forReactQuery: true },
      ),
    {
      onSuccess: ({ message }) => {
        refetchChatParticipants();
        refetchChatRoom();
        toastr.success(message);
      },
      onError: () => {
        toastr.error(t("request_failure_message"));
      },
    },
  );

  const showMemberProfile = (memberPublicId: any) =>
    memberProfileDrawer.show({
      memberPublicId,
    });

  const getDropdownOptions = (participant: any) => [
    {
      value: "view-profile",
      label: t("messaging.actions.view_profile"),
      onClick: () => showMemberProfile(participant.user_public_uid),
    },
    {
      value: participant.admin ? "remove-admin" : "make-admin",
      label: participant.admin
        ? t("messaging.remove_admin")
        : t("messaging.make_admin"),
      onClick: () =>
        handleMakeAdmin({
          participantId: participant.id,
          status: !participant.admin,
        }),
    },
    {
      value: "remove",
      label: t("messaging.remove"),
      onClick: () => removeParticipant({ participantId: participant.id }),
    },
  ];

  const { onlineParticipants, offlineParticipants } =
    mapParticipantsPerStatus(participants);

  const renderParticipantList = (participantList: any) =>
    participantList.map((participant: any) => {
      const dropdownOptions = getDropdownOptions(participant);
      const isCurrentUser = currentParticipant.id === participant.id;
      const shouldShowDropdown = !isCurrentUser && currentParticipant.admin;

      return (
        <button
          type="button"
          className="hover:bg-tertiary messaging-rail__content--member focus-visible:bg-tertiary w-full"
          key={participant.id}
          aria-label={t("messaging.open_member_profile_button_aria_label", {
            name: participant.name,
          })}
          onClick={() => showMemberProfile(participant.user_public_uid)}
        >
          <div className="text-dark flex w-[90%] items-center text-left">
            <div className="mr-3">
              <UserImageStatus
                name={participant.name}
                src={participant.avatar_url}
                status={participant.status}
                size="8"
              />
            </div>
            <div className="truncate font-medium">
              {isCurrentUser ? t("messaging.you") : participant.name}
            </div>
            {participant.admin && (
              <div className="ml-3">
                <BadgeV3 label={t("moderator")} />
              </div>
            )}
          </div>
          {shouldShowDropdown && (
            <Dropdown
              direction="bottom-start"
              menuButtonEl="div"
              button={
                <Dropdown.MenuButton id="action-block">
                  <IconButton
                    name="20-menu-dots-horizontal"
                    iconSize={20}
                    ariaLabel={t("options_dropdown")}
                  />
                </Dropdown.MenuButton>
              }
              options={dropdownOptions}
            />
          )}
        </button>
      );
    });

  return (
    <div
      id="scrollableDiv"
      className="messaging-rail__content h-full overflow-auto"
    >
      <InfiniteScroll
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={Boolean(hasNextPage)}
        dataLength={totalParticipantsCount}
        className="px-6 pb-6"
        scrollableTarget="scrollableDiv"
        loader={<Loader center />}
      >
        {onlineParticipants.length > 0 && (
          <>
            <div className="text-default mb-2">
              <Typography.LabelXxs weight="bold" color="text-default">
                {t("chat_space.online")}
              </Typography.LabelXxs>
            </div>
            {renderParticipantList(onlineParticipants)}
          </>
        )}
        {offlineParticipants.length > 0 && (
          <>
            <div className="text-default my-2">
              <Typography.LabelXxs weight="bold" color="text-default">
                {t("chat_space.offline")}
              </Typography.LabelXxs>
            </div>
            {renderParticipantList(offlineParticipants)}
          </>
        )}
      </InfiniteScroll>
    </div>
  );
};
