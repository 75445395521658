import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { AvatarWithProgress } from "@circle-react/components/Leaderboard/Dashboard/MyProfile/AvatarWithProgress";
import { useLevelColors } from "@circle-react/components/Leaderboard/colorHelper";
import { useGamificationMember } from "@circle-react/components/Profile/HoverCard/useGamificationMember";
import { ProfileActions } from "@circle-react/components/ProfileModalV3/Body/ProfileHeader/Actions";
import { ProfileInfoItem } from "@circle-react/components/ProfileModalV3/Common/ProfileInfoItem";
import { useProfileContext } from "@circle-react/components/ProfileModalV3/context";
import { formatDateString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { MemberTags } from "@circle-react-shared/uikit/MemberTags";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const ProfileHeader = () => {
  const { member, isLoading } = useProfileContext();

  const { data: gamificationMembers } = useGamificationMember({
    enabled: !isLoading,
    member_ids: [member?.id],
  });
  const gamificationMember = gamificationMembers?.[0];

  const visibleMemberTags = member?.member_tags?.filter(
    (memberTag: any) =>
      memberTag?.is_public && memberTag?.display_locations?.profile_page,
  );
  const hasVisibleMemberTags = visibleMemberTags?.length > 0;

  const levelColors = useLevelColors(gamificationMember?.current_level);

  return (
    <div
      className={classNames("flex flex-col items-center py-9 pb-0", {
        [levelColors.bgGradient]: !!gamificationMember,
      })}
      data-testid="profile-modal-header"
    >
      <AvatarWithProgress
        name={member.name}
        avatarUrl={member.avatar_url}
        size="large"
        level={gamificationMember?.current_level}
        progress={gamificationMember?.level_progress}
      />
      <div className="mt-4 flex flex-col items-center space-y-2 px-4 text-center">
        <Typography.LabelLg
          weight="semibold"
          color="text-dark"
          data-testid="member-name"
        >
          {member.name}
        </Typography.LabelLg>
        {member.headline && (
          <Typography.LabelSm
            weight="regular"
            color="text-dark"
            data-testid="member-headline"
          >
            {member.headline}
          </Typography.LabelSm>
        )}
        {member.last_seen_at && (
          <ProfileInfoItem
            iconType="16-clock"
            info={t("profile_drawer.last_seen", {
              last_seen: dateStringToTimeAgo(member.last_seen_at),
            })}
            dataTestId="profile-member-email"
            className="text-light"
          />
        )}
        <ProfileInfoItem
          iconType="16-calendar"
          info={t("profile_drawer.member_since", {
            member_since: formatDateString(member.created_at, {
              month: "long",
              year: "numeric",
              day: "numeric",
            }),
          })}
          dataTestId="profile-member-since"
          className="text-light"
        />
      </div>
      {hasVisibleMemberTags && (
        <div className="mt-4">
          <MemberTags limit={Infinity} memberTags={visibleMemberTags} />
        </div>
      )}
      <div className="mt-7">
        <ProfileActions />
      </div>
    </div>
  );
};
