import { createRef } from "react";
import classNames from "classnames";
import { isFunction } from "lodash";
import { MessageIndicatorLines } from "@/react/components/Chat/ChatWindowV2/MessageItem/MessageIndicatorLines";
import { CursorPagination } from "@/react/components/shared/Pagination/CursorPagination";
import { usePunditUserContext } from "@circle-react/contexts";
import { formattedMessageSentAt } from "@circle-react/helpers/dateTimeHelpers/formattedMessageSentAt";
import type { Message } from "@circle-react/types/CommunityInbox/Message";
import { MESSAGE_CONTAINER_ID } from "../../hooks/useScroll";
import { MessageBox } from "../MessageBox";
import { AIPausedIndicator } from "./AIPausedIndicator";
import { MessageContentItem } from "./MessageContentItem";
import { NewConversationBlock } from "./NewConversationBlock";
import { PreviewConversationBlock } from "./PreviewConversationBlock";
import { UnavailableIndicator } from "./UnavailableIndicator";
import {
  isAIPausedIndicatorSet,
  isAIResumedIndicatorset,
  isIndicatorNeverSet,
} from "./helper";

interface ChatWindowProps {
  messages: Message[];
  createMessage: (data: any) => void;
  isDms: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
  isNewConversation: boolean;
  isPreviewMode?: boolean;
  agentName?: string;
  isProcessing?: boolean;
  isDisabled?: boolean;
  chatCommunityMember?: any;
  currentChat: any;
}

export const ChatWindow = ({
  messages,
  createMessage,
  isDms,
  fetchNextPage,
  hasNextPage,
  isLoading,
  isNewConversation,
  isPreviewMode,
  agentName,
  isProcessing,
  isDisabled,
  chatCommunityMember,
  currentChat,
}: ChatWindowProps) => {
  const prevDayRef: any = createRef<string | null>();
  const shouldDisplayPreviewBlock = isPreviewMode && !messages?.length;
  let aiIndicator: {
    id: number | null;
    isAiSnoozed: boolean | null;
  } = {
    id: null,
    isAiSnoozed: null,
  };

  const { currentCommunityMember, currentCommunitySettings } =
    usePunditUserContext();
  const isCurrentUserCommunityMember =
    currentCommunityMember?.id === chatCommunityMember?.id;
  const { pause_ai_enabled: isPauseAiEnabled } = currentCommunitySettings || {};

  const shouldDisplayMessageBox = isPauseAiEnabled
    ? isDms || !isCurrentUserCommunityMember
    : isDms;

  return (
    <div
      className={classNames(
        "bg-primary flex w-full flex-col justify-between",
        isNewConversation || isPreviewMode
          ? "h-full"
          : "h-[calc(100dvh-64px)] lg:h-[calc(100%-64px)]",
      )}
    >
      {hasNextPage && (
        <CursorPagination
          componentId={MESSAGE_CONTAINER_ID}
          fetchNewPage={fetchNextPage}
          onEvent="scrollUp"
          messageId={null}
          isLoading={isLoading}
        />
      )}
      <div id={MESSAGE_CONTAINER_ID} className="overflow-auto">
        {messages.map((message: Message) => {
          const {
            id,
            ai_snoozed: isAiSnoozed,
            created_at: createdAt,
          } = message;

          const { day } = formattedMessageSentAt(createdAt);
          const isDayChange =
            !isFunction(prevDayRef) && prevDayRef?.current !== day; //null
          if (prevDayRef && !isFunction(prevDayRef)) {
            prevDayRef.current = day;
          }

          if (
            (isIndicatorNeverSet(aiIndicator) ||
              isAIResumedIndicatorset(aiIndicator)) &&
            isAiSnoozed
          ) {
            aiIndicator = {
              id,
              isAiSnoozed,
            };
          } else if (isAIPausedIndicatorSet(aiIndicator) && !isAiSnoozed) {
            aiIndicator = {
              id,
              isAiSnoozed: false,
            };
          }

          return (
            <>
              {!isPreviewMode && (
                <MessageIndicatorLines
                  showDateChangeLine={isDayChange}
                  day={day}
                />
              )}
              {!isPreviewMode && aiIndicator.id === id && (
                <AIPausedIndicator aiPaused={isAiSnoozed} />
              )}
              <MessageContentItem message={message} />
            </>
          );
        })}
        {isDisabled && agentName && (
          <UnavailableIndicator agentName={agentName} />
        )}
        {!isAIPausedIndicatorSet(aiIndicator) && currentChat?.ai_snoozed && (
          <AIPausedIndicator aiPaused={currentChat?.ai_snoozed} />
        )}
        {isAIPausedIndicatorSet(aiIndicator) && !currentChat?.ai_snoozed && (
          <AIPausedIndicator aiPaused={currentChat?.ai_snoozed} />
        )}
      </div>
      {shouldDisplayMessageBox && (
        <div className="px-4 pt-2">
          {isNewConversation && <NewConversationBlock />}
          {shouldDisplayPreviewBlock && <PreviewConversationBlock />}
          {!isDisabled && (
            <MessageBox
              onSubmit={createMessage}
              isProcessing={isProcessing}
              agentName={isDms ? agentName : chatCommunityMember?.name}
              isDms={isDms}
            />
          )}
        </div>
      )}
    </div>
  );
};
