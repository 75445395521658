import { t } from "@/i18n-js/instance";
import { useDevelopersAccessFlags } from "@/react/hooks/developers/useDevelopersAccessFlags";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const DevelopersLinks = () => {
  const { isApiFeatureEnabled } = useDevelopersAccessFlags();

  const links: Link[] = [
    {
      label: t("settings_nav_v3.developers.overview"),
      path: settingsNavPath.developers.overview,
      exact: true,
      isDisabled: !isApiFeatureEnabled,
    },
    {
      label: t("settings_nav_v3.developers.api"),
      path: settingsNavPath.developers.api,
      exact: false,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.developers.title")}>
        {links.map(link => (
          <SubNavLinkItem
            isDisabled={link.isDisabled}
            key={link.path}
            label={link.label}
            path={link.path}
          />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
