import { useMemo } from "react";
import { usePunditUserContext } from "@circle-react/contexts";

interface DevelopersAccessFlagsType {
  isApiFeatureEnabled: boolean;
  isAdminApiFeatureEnabled: boolean;
  isHeadlessApiFeatureEnabled: boolean;
}

const defaultDevelopersAccessFlags: DevelopersAccessFlagsType = {
  isApiFeatureEnabled: false,
  isAdminApiFeatureEnabled: false,
  isHeadlessApiFeatureEnabled: false,
};

export const useDevelopersAccessFlags = (): DevelopersAccessFlagsType => {
  const { currentCommunity, isLoading } = usePunditUserContext();

  return useMemo(() => {
    if (isLoading || !currentCommunity) {
      defaultDevelopersAccessFlags;
    }

    const {
      api_access_feature_flag_enabled: isApiFeatureEnabled,
      admin_api_feature_flag_enabled: isAdminApiFeatureEnabled,
      headless_api_feature_flag_enabled: isHeadlessApiFeatureEnabled,
    } = currentCommunity;

    return {
      isApiFeatureEnabled,
      isAdminApiFeatureEnabled,
      isHeadlessApiFeatureEnabled,
    };
  }, [currentCommunity, isLoading]);
};
